/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/////////////////////    all the playhigh css starts from here     ////////////////////////////////////
.sc-ion-modal-md-h {
  // width: 80%;
  backdrop-filter: blur(4px) contrast(0.5) !important;
  // border-radius: 0px 0px 80px 0px;
}

.sliderModal {
  backdrop-filter: brightness(0.5) blur(2px);
  z-index: 20002;
  height: 100%;
  margin: auto;
  max-width: 400px;

  .modal-wrapper.sc-ion-modal-md {
    margin: auto;
    width: 375px;
    height: 80%;
  }

  ion-backdrop {
    --background: transparent !important;
    -webkit-backdrop-filter: blur(2px) !important;
    backdrop-filter: blur(2px) !important;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.2) !important;
  }

  ion-header {
    ion-toolbar {
      --background: var(--l-Gradient) !important;
    }
  }
}

.sc-ion-loading-md-h {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.72) !important;
  color: #fff !important;
}

.sc-ion-picker-md-h {
  --background: #000 !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.56) !important;
}

.sc-ion-modal-md-h {
  // --background: transparent !important;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.1) !important;
}

// Start write your common classes here
.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.justify-left {
  justify-content: left !important;
}

.justify-right {
  justify-content: right !important;
}

.justify-center {
  justify-content: center !important;
}

.p-0 {
  padding: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.m-auto {
  margin: auto !important;
}
.w-100 {
  width: 100% !important;
}

.d-flex {
  display: flex !important;
}

ion-content {
  --background: var(--bg);
}
ion-header {
  background: var(--bg);
}

ion-label,
ion-title,
p,
ion-text,
span,
ion-input {
  color: #FFFFFF !important;
}

.logup {
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: #de2488 !important;
  }

  h6 {
    text-align: center;
    color: var(--l-Gradient) !important;
  }

  .absol {
    width: 100%;
    max-width: 450px;
    margin: 20px;

    .log-form {
      border-radius: 20px;
      width: 100%;
      margin: auto;
      text-align: left;

      .inside-ele {
        border-bottom: 1px solid #E5E5E5;
        --padding-start: 0px;
        --min-height: 30px;

        .in-ele {
          color: var(--text-color1);
          padding-left: 10px !important;
          --placeholder-color: #B6B6B6;
          font-size: 12px;
          font-weight: 400;
          --padding-top: 0px;
          --padding-bottom: 0px;
          margin-top: 0px;
        }
      }

      .eyeIcon {
        font-size: 17px;
        color: #B6B6B6;
        margin-right: -6px;
      }

      .in-label {
        font-size: 11px;
        line-height: 30px;
        padding: 5px 0px 0px;
      }
    }
  }

  .log-form-btn {
    width: 90%;
    margin: auto;
    margin-top: -12px;

    button {
      width: 90%;
      margin: 0px;
      margin-top: -30px;
      height: var(--btn-height);
      width: 90%;
      font-family: Outfit;
      font-weight: 600;
      text-transform: capitalize;
      background: var(--bg);
      box-shadow: var(--btn-shadow);
      border-radius: 25px;
    }
  }
}

.new-here {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  margin: 20px 20px 0px;
  font-weight: 500;
  font-size: 15px;

  span {
    font-size: 15px;
    font-weight: 600;
    color: var(--theme-color) !important;
  }
}

.logup1 {
  width: 100%;
}

.loginBtn {
  position: absolute;
  width: 325px;
  margin-top: -10px;
  margin-left: 7px;
}

.header-flex {
  margin: 0px 10px 0px 10px;

  ion-back-button {
    z-index: 999 !important;
  }

  .img-menu {
    z-index: 999 !important;
  }

  ion-back-button.img-menu {
    --padding-start: 0px !important;
    --padding-end: 0px !important;
  }

  .header-title {
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    margin-left: -48px;
    text-transform: uppercase;
    letter-spacing: 3px;
  }

  .head-cent {
    width: 100%;
    text-align: center;
    color: #fff !important;
    margin-top: 0px;
    margin-left: -48px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
}

.add-cash-modal {
  width: 100%;
  backdrop-filter: blur(4px);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.1) !important;
  --background: rgba(0, 0, 0, 0.5);

  // .ion-grid {
  //   width: 90%;
  //   margin-top: 25%;
  //   margin-bottom: 120%;
  // }
}

.withdraw-modal {
  width: 100%;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.1) !important;
  --background: rgba(0, 0, 0, 0.1);

  .ion-grid {
    width: 90%;
    margin-top: 50%;
    margin-bottom: 75%;
  }
}

.recent-modal {
  width: 100%;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.1) !important;
  --background: transparent;
  height: 44%;
  top: auto;
  box-shadow: none;
}

.recent-modal-full {
  width: 100%;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.1) !important;
  --background: transparent;
  height: 100%;
  top: auto;
  box-shadow: none;
}

.img-home {
  margin-top: 13px;
}

.opacity {
  filter: opacity(0.5);
}

.gradientBorder {
  // this is common class for common outline gradient buttons
  position: absolute;
  background: #fff;
  border-radius: 24px;
}

.gradientBorder2 {
  padding: 23px 54%;
}

.myTeamBtn {
  // this is common class for common outline gradient buttons
  position: relative;
  z-index: 1;
}

.capN {
  margin: 2px 0;
}

.cap {
  color: #f70c5b;
  margin: 0;
}

.players {
  color: #cfd0d0;
}

// Common classes ends here
ion-button {
  height: var(--btn-height);
  width: 90%;
  font-family: Outfit;
  text-transform: capitalize;
  --border-radius: 10px;
  --background: var(--bg-btn);
  --box-shadow: var(--btn-shadow);
  // --border-radius: var(--btn-radius);
  --color: #fff;
  font-size: 14px;
  font-weight: 600;
}
.btn-teampreview {
  --background: #151A24;
  border: 0.5px solid white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 90%;
}


//Authentication pages css starts
.loginContainer {
  width: 100vw;
  margin: 0 !important;
  // --background: linear-gradient(180deg, #414141 40.31%, #171717 100%);
}

.outerDiv {
  width: 80%;
  height: auto;
  // margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.playerImg {
  position: absolute;
  top: 5vw;
  right: 5vw;
  width: 42px;
  height: auto;

  img {
    width: 100%;
    height: auto;
  }
}

.or {
  color: var(--text-color1);
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}

.orLine {
  margin-top: 15px;
  width: 100%;
  height: 1px;
  border-top: 1px solid #790071;
}

.tagImg {
  position: absolute;
  width: 150px;
  height: auto;
  bottom: 3vw;
  left: 4vw;
  z-index: -1;

  img {
    width: 100%;
    height: auto;
  }
}

.otp {
  // transform: skewY(-30deg);
  position: absolute;
  right: 3vw;
  bottom: 8vw;
  background: var(--ion-color-primary);
  color: var(--text-color1);
  width: 34vw;
  height: 23vw;
  display: flex;
  justify-content: flex-start;
  padding: 0 5px;
  align-items: center;
  border-radius: 3vw;
  letter-spacing: 1.1px;
  line-height: 1.5;

  ion-icon {
    font-size: 24px;
  }
}

.note,
.note2 {

  // font-size: 12px;
  span {
    font-size: 15px;
    line-height: 2;
    color: var(--theme-color);
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: underline;
  }
}

.note {
  color: #000;
}

.error {
  color: #FC077C !important;
  margin: 5px 0px 0px;
  font-weight: 400;
  font-size: 11px;
  padding: 0px;
  line-height: 14px;
  // max-width: 275px;
  text-align: right;
}

.arrowSubmit {
  position: absolute !important;
  bottom: -12vw !important;
  right: 2vw !important;
  width: 80px !important;
  height: 80px !important;

  ion-icon {
    font-size: 30px;
  }
}

//Authentication pages css ends

// dashboard, my-matches header common css starts here
ion-header {
  ion-toolbar {
    .menu {
      width: 40px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .logoHeader {
      top: 0px;
      right: 10px;
      position: absolute;

      img {
        width: 100%;
        height: auto;
      }
    }

    .tabBtn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 56px;
      width: 16vw;
      padding: 0 5px;
      background: #000;
      margin: 0;

      p {
        margin: 0;
        color: #fff;
        word-wrap: break-word;
        font-size: 10px;
        text-align: center;
        padding-top: 5px;
        letter-spacing: 1px;
      }
    }
  }
}

.activeTab {
  background: linear-gradient(#e86567, #985d83) !important;
}

.contentHeader {
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 15px !important;
  line-height: 19px;
  margin: 30px auto;
}

.contentHead {
  font-weight: 500 !important;
  font-size: 16px !important;
  text-align: center;
  color: #fff !important;
  line-height: 18px;
  position: relative;
  width: 85%;
  margin: 10px auto 0px;
  text-transform: uppercase;
}

// .contentHead:before{
//   content: "";
//   width: 60%;
//   max-width: 165px;
//   height: 2px;
//   background: #488955;
//   margin: auto;
//   display: inline-block;
//   position: absolute;
//   right: 0px;
//   left: 0px;
//   bottom: -5px;
// }
// .contentHead:after{
//   content: "";
//   width: 0;
//   height: 0;
//   margin: auto;
//   display: inline-block;
//   position: absolute;
//   right: 0px;
//   left: 0px;
//   bottom: -13px;
//   border-left: 8px solid transparent;
//   border-right: 8px solid transparent;
//   border-top: 8px solid #488955;
// }

.matchList {
  box-shadow: none;
  padding: 10px 0;
}

.cardHeader {
  padding: 0;
  height: 25%;
  border-radius: 0px 0px 80px 80px;
  background: rgba(134, 49, 200, 0.44);
  width: 90%;
  margin: 0% 5%;

  ion-row {
    padding: 5px;
    height: 100%;

    ion-col {
      display: flex;
      align-items: center;
    }
  }
}

.matchName {
  width: 70%;
  word-wrap: break-word;
  color: #000;
}

.matchKind {
  justify-content: flex-end;
  text-align: center;
  word-wrap: break-word;
  color: #000;
}

.matchGrid {
  height: auto;

  ion-row {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    ion-col {
      height: auto;
      padding: 0;
      text-align: center;
    }
  }
}

.teamDiv {
  justify-content: flex-end;
}

.teamDiv2 {
  justify-content: flex-start;
}

.teamDiv,
.teamDiv2 {
  display: flex;
  align-items: center;
  height: 100%;

  .teamLogo {
    width: 6vw;
    height: 6vw;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .tName {
    font-size: 12px;
    word-wrap: break-word;
    color: var(--theme-color);
    padding: 0 5px;
    width: 30vw;
  }

  .mRight {
    margin-right: 5px;
    text-align: right;
  }

  .mLeft {
    margin-left: 5px;
    text-align: left;
  }
}

.vs {
  div {
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    p {
      color: #57AE65;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15px;
      height: 0px;
      border-radius: 50%;
      font-size: 25px;
      font-weight: 600;
    }
  }
}

.matchSchedule {
  text-align: center;
  width: 100%;
  padding: 0;
  margin: 0;
  border-top: 1px solid #3c3c3c;
  height: 20%;

  ion-col {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p {
    color: var(--theme-color);
    margin: 10px 0;
  }

  .timeStatus {
    border-left: 1px solid #3c3c3c;
    border-right: 1px solid #3c3c3c;
  }
}

// dashboard, my-matches header common css ends here

.noMatchesImg {
  width: 50%;
  height: auto;

  img {
    width: 100%;
    height: auto;
  }
}

// contest, prize-details, my-team, select-your-team, c & vc, team-preview common css starts here
.matchContent {
  height: auto;
  width: 94%;
  margin: auto;
  margin-top: 10px;
  background: transparent;

  .matchGrid {
    height: auto;
    padding: 0;
  }
}

.segment-grid2 {
  padding: 10px 0px;
}

.header-segment-contest {
  width: 220px;
  margin: auto;
  padding: 0px;
  // filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.25));
  // backdrop-filter: blur(20px);
  border-radius: 10px;

  ion-segment-button {
    color: #fff;
    text-transform: capitalize;
    --indicator-height: 0px !important;
    width: 100%;
    --ripple-color: transparent;
    margin: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    min-width: 0;
    min-height: 35px;

    div {
      span {
        color: #fff !important;
        font-weight: 300;
        font-size: 14px !important;
        line-height: 18px;
        padding-bottom: 3px;
      }
    }
  }

  ion-segment-button.segment-button-checked {
    color: #de2488 !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

  ion-segment-button.segment-button-checked div span {
    color: #fff !important;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 18px;
    border-bottom: 1px solid #fff;
    padding-bottom: 2px;
  }
}

.expandedBtn {
  width: 92%;
  margin: 0 4%;
  height: var(--btn-height);
  margin-bottom: 10px;
  --color: var(--text-color1);
}

.outlineBgBtn {
  background: var(--ion-color-primary);
  border-radius: 25px;
}

// .outlineColor {
//   background-image: url('./assets/images/btnOutline.png');
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
// }
.btnSolidBg {
  background-image: url("./assets/images/btnSolidBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

// edit-profile, change-password common css starts here
.formHeader {
  background: var(--l-Gradient);
  padding: 0;
  width: 96%;
  margin: auto;
  border-radius: 25px 25px 0 0;
  color: var(--text-color1);
  margin-top: 15px;
}

.updateProfile-btn {
  height: var(--btn-height);
  margin: 2%;
}

.picker-opt.picker-opt-selected,
.picker-prefix,
.picker-suffix {
  color: #f70c5b;
}

.picker-button.sc-ion-picker-md,
.picker-button.activated.sc-ion-picker-md {
  color: #fff;
}

.picker-button.sc-ion-picker-md,
.sc-ion-picker-md {
  color: #fff;
}

.sc-ion-select-popover-h ion-list-header.sc-ion-select-popover,
.sc-ion-select-popover-h ion-label.sc-ion-select-popover {
  color: #fff;
}

// ion-toolbar {
//   --background: linear-gradient(180deg, #fa296f 0%, #e0004b 100%) !important;
// }
ion-menu {
  --min-width: 100%;
}

.btn-create {
  width: 95%;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  --background: transparent;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
}

// .btn-team {
//   width: 100% !important;
//   height: 55px;
//   --background: linear-gradient(180deg, #CD8062 0%, #A46145 100%);
// --box-shadow: 1px -1px 2px rgba(94, 94, 94, 0.3), -1px 1px 2px rgba(23, 23, 23, 0.5), inset -1px -1px 2px rgba(81, 49, 36, 0.2), inset 1px 1px 2px rgba(81, 49, 36, 0.2), inset -1px 1px 2px rgba(255, 173, 126, 0.9), inset 1px -1px 3px rgba(81, 49, 36, 0.9);
// --border-radius: 10px;

// }

// .modal-wrapper {
//   // border-radius: 0px 0px 80px 0px !important;
//   // box-shadow: 0px 0px 4px #f70c5b !important;
// }

.sc-ion-alert-md-h {
  --background: #ffffff !important;
}

.alert-radio-label.sc-ion-alert-md {
  color: #fff !important;
}

.alert-button-inner.sc-ion-alert-md {
  color: #de2488 !important;
  font-weight: 600;
}

.alert-title.sc-ion-alert-md {
  color: #de2488 !important;
  font-weight: 600;
}

.alert-head.sc-ion-alert-md+.alert-message.sc-ion-alert-md {
  color: #000;
}

.country-modal {
  height: 100%;
  margin: auto;
  // width: 95%;

  .modal-wrapper.sc-ion-modal-md {
    margin: auto;
    width: 375px;
    height: 80%;
  }

  ion-backdrop {
    --background: transparent !important;
    -webkit-backdrop-filter: blur(2px) !important;
    backdrop-filter: blur(2px) !important;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.2) !important;
  }
  .searchbar-input.sc-ion-searchbar-md {
    background: transparent !important;
  }
  .searchbar-clear-icon.sc-ion-searchbar-md {
    width: 14px !important;
  }
  ion-header {
    ion-toolbar {
      --background: #f4f5f8;
      border: 1px solid #ED1182;
      border-radius: 5px;
    }
  }
}

.loading-wrapper {
  background: transparent !important;
  box-shadow: none !important;
}

.sliderDashboard {
  .swiper-pagination-bullet-active {
    background: #fff !important;
    border-radius: 2px;
    width: 15px !important;
    height: 6px;
    border-radius: 20px;
    // margin-bottom: -2px !important;
  }

  .swiper-pagination-bullets {
    bottom: 3px !important;
  }

  .swiper-pagination-bullet {
    background: #9F99A7;
    opacity: 1;
    margin: 0 1.5px !important;
    width: 6px;
    height: 6px;
  }
}

.slidermyaccount {
  .swiper-pagination-bullet-active {
    background: linear-gradient(180deg, #681642 0%, #2E041A 110%) !important;
    width: 7px !important;
    height: 7px !important;
    border-radius: 1px !important;
  }

  .swiper-pagination-bullet {
    width: 5px;
    height: 5px;
    background: #941231;
    border-radius: 1px;
    opacity: 1;
    padding-top: 5px !important;
  }
}

ion-back-button::part(icon) {
  content: url("../src/assets/images/backbutton.svg") !important;
  width: 18px !important;
  height: 15px !important;
  margin-top: 0px;
}

.menuModalCss {
  backdrop-filter: blur(5px);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.1) !important;
  background: #00000096;
  width: 125%;

  &::part(content) {
    opacity: 1;
    background: transparent;
    transform: translateX(-20%);
  }
}

ion-datetime {
  // --background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%),
  //   linear-gradient(169.06deg, #212426 0%, #212426 101.24%) !important;
  font-weight: 600;
  color: #000;
}

.calendar-action-buttons ion-item ion-label {
  color: #ffffff;
}

/************** Dashboard and My Matches Card Design **************/
.bg_card {
  height: 200px;
  width: 100%;
  background: #fff;
  position: fixed;
  top: 0px;
  border-radius: 0px 0px 20px 20px;
  z-index: 0 !important;
}

.up-matches-card {
  width: 90%;
  margin: 40px auto 10px;
  padding: 0px;
  overflow: visible;
  background: #202020;
  border: 2px solid #C03194;
  border-radius: 15px;
  transform: perspective(300px) rotateX(15deg);

  .cname_row {
    .teamimg_col {
      border-radius: 35px;
      padding: 8px;
      z-index: 99;
      display: flex;
      position: absolute;
      top: -35px;

      img {
        padding: 10px;
        width: 50px;
        height: 50px;
        background: radial-gradient(61.87% 61.87% at 50% 50%, #000000 56.83%, #D3248D 83.39%);
        border-radius: 30px;
      }
    }

    .left_img {
      left: 20px;
      background: rgba(196, 196, 196, 0.1);
    }

    .right_img {
      right: 20px;
      background: rgba(196, 196, 196, 0.1);
    }

    .center_timer {
      display: flex;
      justify-content: center;
      width: 120px;
      margin: auto;
      z-index: 999 !important;
      position: absolute;
      right: 0px;
      left: 0px;
      top: -20px;

      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        margin: 0px;
        letter-spacing: -0.59366px;
      }
    }

    .cname_col {
      p {
        display: block;
      }

      .top_p {
        // background: #D4238D;
        background: url(assets/images/p_bg.svg) no-repeat;
        background-size: cover;
        color: #fff;
        margin: auto;
        width: 115px;
        height: 25px;
        padding: 2px 0px;
        margin-top: -1px;
        text-align: center;
      }
      @media (min-width: 320px) and (max-width: 340px) {
        .top_p {
          width: 100px;
          height: 22px;
        }
      }
      @media (min-width: 0px) and (max-width: 319px) {
        .top_p {
          width: 80px;
          height: 18px;
        }
      }
    }
  }

  .cont-color {
    text-align: center;
    letter-spacing: -0.289366px;
    margin: 10px auto 10px;
    width: 90%;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    color: #FFF !important;
  }

  .dis-fles {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background: linear-gradient(90.63deg, #FE057B 2.76%, #BB3496 98.03%);
    border-radius: 0px 0px 10px 10px;

    ion-col {
      p {
        letter-spacing: 0.005em;
        margin: 0px;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #fff !important;
        padding: 5px 0px;
      }
    }

    .vs_col {
      div {
        // width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;

        img {
          width: 25px;
          height: 25px;
        }
      }
    }

    .teamabbr1 {
      margin: 0 auto;

      p {
        padding-left: 5px;
      }
    }

    .teamabbr2 {
      margin: 0 auto;

      p {
        padding-right: 5px;
      }
    }

    .team_nm_bg {
      // background: url(assets/images/team_nm_bg.png) no-repeat;
      // background-size: 100% 100%;
      // height: 30px;
      align-items: center;
    }
  }

  .teamname_row {
    width: 95%;
    margin: auto;

    .team_nm {
      color: #474747 !important;
      margin: 0px;
      display: flex;
      text-align: center;
      align-items: baseline;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
    }
  }
}


ion-footer {
  background: var(--bg) !important;
}


// <--------------------- PWA CSS starts Here ------------------------->

.bg-pwa {
  height: 100%;
  width: 100%;
  // ion-content {
  //   height: calc(100% - 118px) !important;
  // }
}

.content {
  width: 100%;
  height: 100%;
  // overflow-y: scroll;
  // padding-right: 17px;
  /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box;
  /* So the width will be 100% + 17px */
  overflow-x: hidden;
  z-index: 99 !important;
  position: relative !important;

}

.toastCss {
  z-index: 60001;
  max-width: 400px;
  font-weight: 600;
  --background: #292c2f;
}

.alertClass {
  font-weight: 600;
  border-radius: 0px;
  background: transparent;
  padding: 10px;

  .alert-title {
    color: #141414;
    font-weight: 600;
  }

  .alert-message {
    color: #141414;
  }
}

@media(min-width : 500px) {
  .sliderModal {
    border-radius: 20px;

    .ion-grid {
      margin-top: 0vh !important;
    }
  }
}

@media (min-width: 500px) and (max-width : 1200px) {
  .bg-pwa {
    height: 100%;
    width: 100%;
    background-image: url("./assets/side_bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .inner-bg-pwa {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    ion-content {
      width: 400px !important;
      position: absolute;
      top: 50% !important;
      left: 50% !important;
      height: calc(100% - 121px) !important;
      transform: translate(-50%, -50%);
      border-radius: 0px 0px 20px 20px;

      // --padding-top: 60px;
      // --padding-bottom: 60px;
      &::part(background) {
        margin: -5px 0px;
      }

      .content {
        width: 100%;
        height: 100%;
        // overflow-y: scroll;
        // padding-right: 17px;
        /* Increase/decrease this value for cross-browser compatibility */
        box-sizing: border-box;
        /* So the width will be 100% + 17px */
      }
    }

    ion-header {
      width: 400px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      z-index: 99999;
      border-radius: 20px 20px 0px 0px;

      ion-toolbar {
        border-radius: 20px 20px 0px 0px;
      }
    }

    ion-footer {
      width: 400px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 0px 0px 20px 20px;

      ion-toolbar {
        padding: 0px;
        border-radius: 0px 0px 20px 20px;
        background: var(--bg);
      }
    }

    .toastCss {
      position: absolute;
      top: 50% !important;
      left: 50% !important;
      height: 100% !important;
      transform: translate(-50%, -50%);
    }

    .alertClass {
      width: 400px;
      margin: auto;
      justify-content: center;
      z-index: -1;
      border-radius: 0px 0px 20px 20px;
    }

    .alert-header {
      width: 400px;
      margin: auto;
      justify-content: center;
      z-index: -1;
      border-radius: 0px 0px 20px 20px;
    }
  }

  ion-modal {
    height: 100%;
    width: 100%;
    // --ion-backdrop-color: transparent;
    --box-shadow: none;

    --backdrop-opacity: 0.4 !important;
    --height: 100% !important;
    --width: 100% !important;
    // border-radius: 20px !important;
    --border-radius: 20px !important;
    border-radius: 20px;
    ion-content {
      height: 100% !important;
      width: 100% !important;
      position: absolute;
      top: 0px !important;
      left: 0vw !important;
    }
  }

  .team-preview-modal {
    --height: 100% !important;
    width: 400px;
    margin: auto;
    justify-content: center;
    top: 0vh;
    --border-radius: 20px;
  }

  .add-cash-modal,
  .withdraw-modal {
    --height: 100% !important;
    width: 400px;
    margin: auto;
    justify-content: center;
    top: 0vh;
    --border-radius: 20px;

    ion-title {
      text-align: center !important;
      width: 86% !important;
    }

    .preview-back-button {
      right: 60px !important;
    }
  }

  .loadingCss {
    height: 100%;
    width: 400px;
    margin: auto;
    justify-content: center;
    border-radius: 20px;
  }

  .date-show {
    justify-content: flex-start;
    padding-left: 12.2vw;
  }

  .shadowContentBg {
    &::part(background) {
      border-radius: 20px;
    }
  }

  .picker-md .picker-wrapper {
    width: 400px;
  }

  .modal-wrapper.sc-ion-modal-md {
    box-shadow: none;
  }

  .country-modal {
    width: 400px;
  }

  .playersContainer {
    margin-top: 160px;
  }

  .segment-grid2 {
    margin-top: 13px;
  }
}













@media (min-width: 1201px) {
  .bg-pwa {
    .inner-bg-pwa {
      margin: 30px 10px;
    }

    height: 100%;
    width: 100%;
    background-image: url('./assets/side_bg.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .bg_card {
      width: 400px !important;
      border-radius: 20px;
    }

    ion-content {
      width: 400px !important;
      left: 10vw;
      .content {
        width: 100%;
        height: 100%;
        // overflow-y: scroll;
        // padding-right: 17px;
        /* Increase/decrease this value for cross-browser compatibility */
        box-sizing: border-box;
        /* So the width will be 100% + 17px */
        border-radius: 20px;
      }
    }

    ion-header {
      width: 400px;
      left: 10vw;
      position: relative;
      z-index: 99999;
      border-radius: 20px 20px 0px 0px;

      ion-toolbar {
        border-radius: 20px 20px 0px 0px;
      }
    }

    ion-footer {
      width: 400px;
      left: 10vw;
      border-radius: 0px 0px 20px 20px;
      // z-index: -1;
      // border-radius: 0px 0px 20px 20px;
      // top: -8px;

      ion-toolbar {
        padding: 10px 0px;
        border-radius: 0px 0px 20px 20px;
        background: var(--bg);
      }
    }

    .toastCss {
      left: 10vw;
    }

    .alertClass {
      width: 400px;
      left: 10vw;
      z-index: -1;
      border-radius: 0px 0px 20px 20px;
    }

    .alert-header {
      width: 400px;
      left: 10vw;
      z-index: -1;
      border-radius: 0px 0px 20px 20px;
    }
  }

  ion-modal {
    height: 100%;
    width: 100%;
    --ion-backdrop-color: transparent;
    --box-shadow: none;

    ion-content {
      height: 100% !important;
      width: 100% !important;
      position: absolute;
      top: 0px !important;
      left: 0vw !important;
    }
  }


  .team-preview-modal {
    --height: 100% !important;
    width: 400px;
    left: 10vw;
    top: 0vh;
    --border-radius: 20px;
  }

  .add-cash-modal,
  .withdraw-modal {
    --height: 100% !important;
    width: 400px;
    left: 10vw;
    top: 0vh;
    --border-radius: 20px;

    ion-title {
      text-align: center !important;
      width: 86% !important;
    }

    .preview-back-button {
      right: 60px !important;
    }
  }

  .loadingCss {
    height: 100%;
    width: 400px;
    left: 10vw;
    border-radius: 20px;
  }

  .date-show {
    justify-content: flex-start;
    padding-left: 12.2vw;
  }

  .shadowContentBg {
    &::part(background) {
      border-radius: 20px;
    }
  }

  .picker-md .picker-wrapper {
    width: 400px;
    margin-left: 10vw;
  }

  .modal-wrapper.sc-ion-modal-md {
    box-shadow: none;
  }

  .country-modal {
    position: absolute;
    width: 400px !important;
    --height: 100% !important;
    height: 100%;
    left: 10vw;
    top: 0vh;
    --border-radius: 20px;
    margin: 0px;
    ion-header {
      position: absolute !important;
      left: 0vw !important;
    }

    .modal-wrapper.sc-ion-modal-md {
      position: absolute !important;
      left: 8.5vw !important;
    }
  }

  .playersContainer {
    margin-top: 265px;
  }
}

// @media (min-height: 800px) {
// .logup1 {
//   margin-top: 20%;
// }
// }

@media (min-width: 0px) and (max-width:320px) {

  ion-label,
  ion-title,
  p,
  ion-text,
  span,
  ion-input {
    font-size: 9px !important;
    color: #000;
  }

  ion-button {
    font-size: 12px;
  }
}

@media (min-width:320px) and (max-width:340px) {

  ion-label,
  ion-title,
  p,
  ion-text,
  span,
  ion-input {
    font-size: 11px !important;
    color: #000;
  }
}

.slide_outer {
  .sliderDashboard {
    .swiper-pagination {
      bottom: -20px !important;
    }

    .swiper-container-horizontal>.swiper-pagination-bullets {
      bottom: -20px !important;
    }
  }
}

.content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.content {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

//galaxy fold and nest hub device
@media (max-height:512px) and (max-width:718px),
(max-height:600px) and (max-width:1024px) {

  .add-cash-modal,
  .withdraw-modal {

    // ion-title {
    //   width: 62% !important;
    // }
    // .ion-grid {
    //   width: 90% !important;
    //   margin-top: 20% !important;
    // }

  }
}

@media (max-width : 1000px) and (min-height : 1100px) {
  .inner-bg-pwa {
    margin: 20% !important;
  }
}

@media (width : 1024px) and (height : 1366px),
(width : 1024px) and (height : 1366.4px) {
  .inner-bg-pwa {
    margin: 20% !important;
  }
}

@media (min-height:340px) and (max-height:370px) {

  .add-cash-modal,
  .withdraw-modal {
    // .ion-grid {
    //   margin-top: 4% !important;
    // }
  }
}

// PWA End
.myacountslide {
  .swiper-pagination-bullet-active {
    background: #25AC60 !important;
    width: 10px;
    height: 10px;
    border-radius: 50px;
  }

  .swiper-pagination-bullets {
    bottom: -5px !important;
  }

  .swiper-pagination-bullet {
    background: #219453;
    opacity: 1;
  }
}

*:-webkit-autofill,
*:-webkit-autofill:hover,
*:-webkit-autofill:focus,
*:-webkit-autofill:active {
  transition: all 5000s ease-in-out 0s;
  transition-property: background-color, color;
  -webkit-text-fill-color: auto;
}

.my-super-class {
  background: var(--item-bg);
  border-radius: 12px !important;
  border: 1px solid #fff !important;
  color: #fff;
}
@media (max-width: 320px) {
  .my-super-class {
    width: 28px !important;
    height: 28px !important;
    border-radius: 8px !important;
    font-size: 15px !important;
  }
}
.otp-modal {
  backdrop-filter: blur(3px);
  --background: none;
}
@media (min-width: 500px) and (max-width: 1200px) {
  .otp-modal {
    max-width: 400px;
    margin: auto;
  }
}
@media (min-width: 1201px) {
  .otp-modal {
    width: 400px !important;
    --height: 100% !important;
    left: 10vw;
    top: 0vh;
    --border-radius: 20px;
  }
}

.content{
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.content::-webkit-scrollbar {
  display: none;
}
// ion-datetime
// {
//   :host .calendar-day {
//     padding: 20px 0px 15px 0px;
//     font-size: 12px;
// }
// }
// .calendar-day {
//   padding: 20px 0px 15px 0px !important;
//   font-size: 12px !important;
// }
// ion-datetime::part(date) .calendar-month-grid {
//   padding: 0px 10px !important;
//   margin-bottom: 10px !important;
//   margin-top: -10px !important;
// }

.calendar-action-buttons ion-item ion-label {
  --color: #595959 !important;
}